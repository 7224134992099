<template>
  <div class="newList solutionsBox">
<!--   <Banner :BannerImg="BannerImg"></Banner>-->
    <div class="content">
      <h3 class="t">企业各层级在人才发展方面的关注点/痛点</h3>
      <div class="content boxTabs">
        <!-- <el-tabs tab-position="left" style="height: 600px;" class="book">
          <el-tab-pane :label="list.title" v-for="list in bookList" :class="list.className">
            <div class="box" :class="list.className">
              <span class="title">高层管理者</span>
              <div class="contentt"></div>
              <span class="foot"></span>
            </div>
          </el-tab-pane>
        </el-tabs>  -->
        <div class="bookBox">
          <div class="left">
            <ul>
              <li v-for="list in bookList" :class="list.className">{{ list.title }}</li>
            </ul>
          </div>
          <div class="right">
            <div class="box" v-for="list in bookRightList">
              <span class="title">{{list.title}}</span>
              <div class="contentt" >
                <ul v-for="(key,index) in list.info" :class="'a' + index">
                  <li v-for="ele in key">{{ ele.title }}</li>
                </ul>
              </div>
              <span class="foot"></span>
            </div>
          </div>
        </div>
      </div>
      <h3 class="t">人才测评-管理者招聘晋升选拔、评价的手段</h3>
    </div>
    <div class="bgfff clearfix">
      <div class="content mobileC">
        <div class="left">
          <div class="one">
            <span class="title">人岗匹配原理</span><br/>
            <span class="info">不同部门、职位对任职者的素质要求是不尽相同的，工作要求是进行素质测评的客观要求，担任一定工作角色的人必须具备相应的素质条件</span>
<!--            <span class="info">是进行素质测评的前提</span>-->
          </div>
          <div class="two">
            <span class="title">人岗匹配原理</span><br/>
            <span class="info mt-10 bgSpan"> 客服人员</span>
            <span class="info bgSpan"> 招聘人员</span>
            <span class="info bgSpan"> 管理人员</span>
          </div>
          <div class="three">
            <span class="title">个体素质差异原理</span><br/>
            <span class="info mt-10">个体差异的客观性与普遍性</span>
            <span class="info">是进行素质测评的前提</span>
            <span class="info mt-10 bgSpan"> 生理差异</span>
            <span class="info bgSpan"> 心理差异</span>
            <span class="info bgSpan"> 社会差异</span>
          </div>
          </div>
        <div class="right">
          <div class="title"> 人才评价体系其特点是多维度、多方法、多评价者</div>
          <table class="tabel">
            <thead>
              <tr>
                <td class="a center">工具</td>
                <td class="b center">定义</td>
                <td class="c center">方式</td>
                <td class="d center">时间</td>
              </tr>
            </thead>
            <tr v-for="list in tableList" class="trD">
              <td>{{ list.a }}</td>
              <td><img src="../../assets/images/arror.png" alt="" class="img">{{ list.b }}</td>
              <td>{{ list.c }}</td>
              <td>{{ list.d }}</td>
            </tr>
          </table>
          <div class="solutitonsBtn">
            <el-button type="warning" @click="$refs.modalBoxPage.dialogVisible = true" style="background-color: #E6A23C;border-color: #E6A23C;">获取解决方案</el-button>
          </div>
        </div>
      </div>
    </div>
    <h3 class="t">课程收益</h3>
    <div class="threeBox mb-50">
      <el-row  :gutter="20">
        <el-col :span="8" v-for="list in threeBoxList" class="info">
          <div class="body">
            <img :src="list.icon" alt="" class="icon">
            <h3 style="border-bottom: #0b0b0b 1px solid; padding-bottom: 15px;">{{list.title}}</h3>
            <p v-html="list.value" style="margin-top: 20px; color: #333333;"></p>
<!--            <el-button type="primary" size="mini" @click="$refs.modalBoxPage.dialogVisible = true" class="btn__" style="background-color: #E6A23C;border-color: #E6A23C;">免费获取解决方案</el-button>-->
          </div>
        </el-col>
      </el-row>
    </div>
    <modalBox ref="modalBoxPage" />
  </div>
</template>

<script>
// import BannerImg from '@/assets/images/tax-swiper.jpg';
// import Banner from '../../components/Banner.vue';
// import ClickModel from '../../components/ClickModel.vue';
import modalBox from '../index/components/modal.vue';
export default {
  name: "solutionsPage",
  components:{modalBox},
  data() {
    return {
      // BannerImg: BannerImg,
      tableList: [
        { a: "天赋解码", b: '为每个人提供一份完整的、科学的、准确的人生规划说明书', c: '书面作答', d: '1H-1.5H' },
        { a: "PDP行为特质", b: '衡量个人的行为特质、活力、动能、压力、精力及能量变动情况', c: '书面作答', d: '1H-1.5H' },
        { a: "GPSAC导航", b: '根据一系列特定的场景，找到障碍与解答，提升能量更轻易的达成目标。', c: '多对一面谈', d: '1Day-2Day' },
        { a: "数字能量", b: '通过数字发现个体的天赋和性格特点', c: '一对一面谈', d: '0.5H' },
        // { a: "公文筐测试", b: '根据评价标准设计结构化问题', c: '书面作答', d: '1H-1.5H' },
        // { a: "公文筐测试", b: '根据评价标准设计结构化问题', c: '书面作答', d: '1H-1.5H' }
      ],
      bookList: [
        { title: "选",className:"a" },
        { title: "用" ,className:"b"},
        { title: "育",className:"c" },
        { title: "留",className:"d" },
      ],
      bookRightList: [
        {
          title: "高层管理者",
          info: {
            a: [
              { title: "①企业的关键业务流程是否有强有力的团队支撑" },
              { title: "②企业的关键岗位是否达到了人岗匹配" }],
            b: [
              { title: "③如何让各部门持续改进和提升绩效水平"},
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ],
            c: [
              { title: "④人才的数量和质量能否持续满足企业长期发展的需要" },
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ],
            d: [
              { title: "⑤如何构建能够吸引优秀人才的雇主品牌" },
              { title: "⑥如何把敬业文化渗透到每一个员工" }
            ]
          },
        },
        {
          title: "中基层管理者",
          info: {
            a: [
              { title: "①如何有效地识别评价优秀人才" },
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ],
            b: [
              { title: "②如何让员工持续改进和提升绩效水平" },
              { title: "③如何管理好新生代员工" },
              { title: "④无法通过单一的绩效考核保证员工的执行力" }
            ],
            c: [
              { title: "⑤如何让员工能够快速上岗" },
              { title: "⑥“工学矛盾”，培养手段单一，企业提供的培训作用不大" }
            ],
            d: [
              { title: "⑦如何通过除现金激励以外的其他手段来激励和保留员工" },
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ]
          },
        },
        {
          title: "基层执行者或基层员工",
          info: {
            a: [
              { title: "①晋升没有明确公开的标准，不知道朝哪个方向努力" },
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ],
            b: [
              { title: "②绩效考核走形式，考核结果的影响作用很小，不知道如何改进绩效" },
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ],
            c: [
              { title: "③培训的内容跟工作、职业发展关系不大，而且浪费占用了很多时间" },
              // { title: "企业的关键岗位是否达到了人岗匹配" }
            ],
            d: [
              { title: "④看不到职业发展的希望" },
              { title: "⑤领导不懂得怎么关心培养下属，团队氛围不佳" },
              { title: "⑥企业文化只是口号，与我无关" },
              { title: "⑦薪酬没有竞争力，随时考虑跳槽" }
            ]
          },
        },
        ],
        threeBoxList:[
          {value:"通过人才盘点与评价，引进外部人才以及提拔内部人才完善人岗匹配", title:"完善人岗匹配", icon:require("@/assets/images/icon1.png")},
          {value:"通过激励和案例沉淀强化岗位归属感，加强员工的稳定性", title:"稳定梯队建设", icon:require("@/assets/images/icon2.png")},
          {value:"帮员工胜任岗位并不断挖掘成长潜力，为企业打造良将如潮的人才梯队", title:"锻造人才根基", icon:require("@/assets/images/icon3.png")},
        ],
      }
  },
  methods: {
    }
  }
</script>
<style lang="less" scoped>
  .moblile{
    .bgfff{
      height:auto;
    }
    h3.t{
      line-height:1.7;
    }
    .mobileC {
      .left{
        width:100%;
        .one{
          padding:40px 0 0 35px;
        }
        .two{
          padding: 14px 0 0 41px;
        }
        .three{
          right:28px
        }
      }
      .right{
        width:100%;
        .tabel{
          .trD td{
            line-height:1.6;
          }
        }
        .solutitonsBtn{
          margin-bottom:20px;
        }
      }
    }
  }
</style>
