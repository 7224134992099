<template>
  <el-dialog
  title="免费获取解决方案"
  :visible.sync="dialogVisible"
  :before-close="handleClose" class="dialogBox" width="980px">
  <div class="info">
    老邦讲堂将在收到申请的24小时内联系您，<br/>
    请保持电话畅通
  </div>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
    <el-form-item label="姓名：" prop="name">
      <el-input v-model="ruleForm.name"></el-input>
    </el-form-item>
    <el-form-item label="手机：" prop="phone">
      <el-input v-model="ruleForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="公司：" prop="company">
      <el-input v-model="ruleForm.company"></el-input>
    </el-form-item>
    <el-form-item label="咨询内容：" prop="mark">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="ruleForm.mark">
      </el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="save()" size="mini">提交信息</el-button>
  </span>
</el-dialog>
</template>

<script>
  export default {
  name: "modalIndexPage",
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: "",
        phone: "",
        company:"",
      },
      rules: {
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请填写手机', trigger: 'blur' }],
        company: [{ required: true, message: '请填写公司', trigger: 'blur' }],
        mark:[{ required: true, message: '请填写咨询内容', trigger: 'blur' }]
        }
      }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {

         }
      })
    }
    }
  }
</script>

<style lang="scss" scoped>

</style>
